// libs
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { Outlet, createSearchParams, useLoaderData, useLocation, useNavigate } from "react-router-dom";

// components
import { Header } from "./Header";
import { SideMenu } from "./SideMenu";
import { Main } from "./components/Main";
import LoadingModal from "./LoadingModal";
import { DrawerHeader } from "./components/DrawerHeader";
import { Alert, AlertProps, AlertTitle, Button, Grid, Typography } from "@mui/material";

// services
import { fetchUserGroupAndSandboxes } from "../../services";

// stores
import { setUserInfo } from "../../store/user-info-reducer";
import { startLoading, stopLoading } from "../../store/loader-reducer";

// hooks
import { useAlertMessageHandler } from "../../hooks/useErrorMessageHandler";
import { SandboxGroupMembership, UserSandboxAccess } from "../../utils/appsync/schema/API";

// constants
import { ErrorEnum } from "../../constants/Error";

const PageContainer: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const userSessionInfo: any = useLoaderData();

    const { username, isUserAuthorized, allocationType, alertMessage, isDismissible, enableRefreshCTA } = useSelector((state: any) => ({
        username: state.userInfo.username,
        isUserAuthorized: state.userInfo.isUserAuthorized,
        allocationType: state.userInfo.allocationType,
        selectedSandbox: state.userInfo.selectedSandbox,
        alertMessage: state.pageAlert.alertMessage,
        isDismissible: state.pageAlert.isDismissible,
        enableRefreshCTA: state.pageAlert.enableRefreshCTA,
    }));

    const { showErrorAlertMessage, hideErrorAlertMessage } = useAlertMessageHandler();

    const [error, setError] = useState<any>([]);
    const [isError, setIsError] = useState<Boolean>(false);

    const updateSandboxInUrl = (data: SandboxGroupMembership, selectedSandbox: UserSandboxAccess): UserSandboxAccess => {
        const selectedSandboxId = new URLSearchParams(location.search).get("sandboxId");
        const selectedSandboxFromUrl = data.sandboxes_accessible?.filter(
            (sandbox: UserSandboxAccess) => sandbox.sandbox_id === selectedSandboxId,
        )?.[0];

        // TODO: If selectedSandbox is null, throw error and navigate to home
        if (!selectedSandboxFromUrl) {
            if (selectedSandboxId) {
                showErrorAlertMessage(ErrorEnum.UNAUTHORIZED);
            }
            navigate({
                pathname: "/",
                search: createSearchParams({
                    sandboxId: data.sandboxes_accessible?.[0]?.sandbox_id,
                    allocationType: allocationType.key,
                }).toString(),
            });
        } else {
            selectedSandbox = selectedSandboxFromUrl;
        }
        return selectedSandbox;
    };

    const fetchSandboxList = async (username: string, email: string): Promise<void> => {
        if (username) {
            dispatch(startLoading());
            try {
                const resp: any = await fetchUserGroupAndSandboxes(username);
                if (resp.errors?.length) {
                    setError(resp);
                    setIsError(true);
                    return;
                }
                const data = resp.GetGroupsUserIsMemberOf;
                let selectedSandbox = data.sandboxes_accessible?.[0];
                selectedSandbox = updateSandboxInUrl(data, selectedSandbox);
                dispatch(
                    setUserInfo({
                        username,
                        email,
                        isUserAuthorized: !!data.sandboxes_accessible?.length,
                        sandboxes: data.sandboxes_accessible ?? [],
                        selectedSandbox: selectedSandbox,
                    }),
                );
            } catch (reason: any) {
                setError([{ errorType: reason.message }]);
                setIsError(true);
            }
            dispatch(stopLoading());
        }
    };

    useEffect(() => {
        if (!userSessionInfo.cognitoInfo) {
            return;
        }
        const username = (userSessionInfo.cognitoInfo.username || "").replace(userSessionInfo.settingsData.cognito.providerName + "_", "");
        const email = userSessionInfo.cognitoInfo.signInUserSession?.idToken?.payload?.email || "";
        fetchSandboxList(username, email);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSessionInfo]);

    useEffect(() => {
        if (isError && error?.errors) {
            showErrorAlertMessage(error.errors);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError, error]);

    const { open, pageTitle } = useSelector(({ sideMenu, pageTitle }: any) => ({
        open: sideMenu.open,
        pageTitle,
    }));

    const renderAlertMessage = (): React.ReactElement => {
        const onRefresh = (): void => {
            if (window?.location) {
                window.location.reload();
            }
        };
        if (!alertMessage || isDismissible) return <React.Fragment></React.Fragment>;

        const alertProps: AlertProps = {
            severity: alertMessage.severity,
        };

        if (enableRefreshCTA) {
            alertProps.action = (
                <Button color="inherit" size="small" onClick={onRefresh}>
                    Refresh
                </Button>
            );
        } else {
            alertProps.onClose = hideErrorAlertMessage;
        }

        return (
            <Alert {...alertProps} sx={{ position: "absolute", width: "calc(100% - 8px)", zIndex: 10 }}>
                <AlertTitle>{alertMessage.title}</AlertTitle>
                {alertMessage.content}
            </Alert>
        );
    };

    return (
        <React.Fragment>
            <Header isUserAuthorized={isUserAuthorized} />
            {isUserAuthorized && <SideMenu />}
            <Main
                open={open}
                isUserAuthorized={isUserAuthorized}
                sx={{
                    padding: open ? "16px 8px 16px 0" : "16px",
                    position: "relative",
                }}>
                <Grid container>
                    <Grid
                        item
                        sx={{
                            flexGrow: 1,
                            maxWidth: "100%",
                        }}>
                        <DrawerHeader />
                        {renderAlertMessage()}
                        {!!pageTitle?.title && (
                            <Typography variant="h6" mb={2}>
                                {pageTitle.title}
                            </Typography>
                        )}
                        {!!username && <Outlet />}
                    </Grid>
                </Grid>
            </Main>
            <LoadingModal />
        </React.Fragment>
    );
};

export default PageContainer;
