// libs
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// store
import { setPageTitle } from "../../store/page-title-reducer";

// styles
import { theme } from "../../styles";

// components
import { Grid } from "@mui/material";
import { Tenets } from "./components/Tenets";
import { A3SIntro } from "./components/A3SIntro";
import { MoreResources } from "./components/MoreResources";
import { SelectConfiguration } from "./components/SelectConfiguration";

// colors
import { Primary } from "../../styles/colors";

const Home: React.FC = () => {
    const dispatch = useDispatch();

    const isUserAuthorized: boolean = useSelector((state: any) => state.userInfo.isUserAuthorized);

    useEffect(() => {
        dispatch(
            setPageTitle({
                title: "",
                crumbs: [],
                endToEnd: true,
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container flexDirection="column" sx={{ p: 0, overflow: "none", position: "relative" }}>
            <Grid
                item
                container
                sx={{
                    py: 4,
                    backgroundColor: Primary[10], //theme.palette.secondary.main,
                    color: theme.palette.primary.contrastText,
                    boxShadow: "none",
                    borderRadius: 0,
                }}>
                <A3SIntro />
                <SelectConfiguration isUserAuthorized={isUserAuthorized} />
            </Grid>
            <Grid item container sx={{ position: "absolute", top: "92%" }}>
                <Tenets />
                <MoreResources />
            </Grid>
        </Grid>
    );
};

export default React.memo(Home);
