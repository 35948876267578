// libs
import { createSlice } from "@reduxjs/toolkit";

// types
import { IAlert } from "../types/Alert";

export type PageAlertState = {
    alertMessage?: IAlert | undefined;
    isDismissible?: boolean;
    enableRefreshCTA?: boolean;
};

export const pageAlertSlice = createSlice({
    name: "pageAlert",
    initialState: {
        alertMessage: undefined,
        isDismissible: true,
        enableRefreshCTA: false,
    },
    reducers: {
        showAlertMessage: (state: PageAlertState, action: { payload: PageAlertState }) => {
            state.alertMessage = action.payload.alertMessage;
            state.enableRefreshCTA = action.payload.enableRefreshCTA;
            state.isDismissible = false;
        },
        hideAlertMessage: (state: PageAlertState) => {
            state.alertMessage = undefined;
            state.enableRefreshCTA = false;
            state.isDismissible = true;
        },
    },
});

export const { showAlertMessage, hideAlertMessage } = pageAlertSlice.actions;

export default pageAlertSlice.reducer;
