// libs
import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSearchParams } from "react-router-dom";

// store
import { setAllocationType, setSelectedSandbox } from "../../../../store/user-info-reducer";

// components
import { Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";

// styles
import { theme } from "../../../../styles";
import styles from "./SelectConfiguration.module.scss";

// constants
import { ALLOCATION_TYPES } from "../../../../utils/constants";

// defs
import { UserSandboxAccess } from "../../../../utils/appsync/schema/API";

// icons
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// props
export interface ISelectConfigurationProps {
    isUserAuthorized: boolean;
}

const SelectConfiguration: React.FC<ISelectConfigurationProps> = ({ isUserAuthorized }) => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { sandboxes, allocationType, selectedSandbox } = useSelector((state: any) => ({
        sandboxes: state.userInfo?.sandboxes,
        allocationType: state.userInfo?.allocationType,
        selectedSandbox: state.userInfo?.selectedSandbox,
    }));

    const [selectedAllocationType, setSelectedAllocationType] = useState(allocationType.id);

    const [sandbox, setSandbox] = useState(selectedSandbox);

    useEffect(() => {
        setSandbox(selectedSandbox);
        setSelectedAllocationType(allocationType.id);
    }, [allocationType, selectedSandbox]);

    const onSelectSandbox = (selectedSandboxId: string): void => {
        setSandbox(sandboxes.filter((sandbox) => sandbox.sandbox_id === selectedSandboxId)[0]);
    };

    const onSelectConfiguration = (): void => {
        dispatch(setAllocationType(selectedAllocationType));
        dispatch(setSelectedSandbox(sandbox));
        navigate({
            pathname: "/simulations",
            search: createSearchParams({
                sandboxId: sandbox.sandbox_id,
                allocationType: allocationType.key,
            }).toString(),
        });
    };

    const renderSelectConfigurations = (): React.ReactElement => {
        if (!isUserAuthorized) {
            return (
                <>
                    <Grid item flexDirection="column" flex="3">
                        <Typography>Get started</Typography>
                        <Button
                            target="_blank"
                            href="https://approvals.amazon.com/Template/Details/160112/"
                            rel="noopener noreferrer"
                            sx={{
                                padding: "0",
                                display: "flex",
                                columnGap: "4px",
                                justifyContent: "flex-start",
                                textTransform: "none",
                                color: theme.palette.primary.main,
                            }}>
                            <Typography>Request Sandbox Access</Typography>
                            <OpenInNewIcon fontSize="small" />
                        </Button>
                    </Grid>
                    <Grid item flex="1">
                        <Button size="large" variant="contained" disabled sx={{ textTransform: "none" }}>
                            Start
                        </Button>
                    </Grid>
                </>
            );
        }

        return (
            <>
                <Grid item container flexDirection="column" flex="3" rowGap="12px">
                    <FormControl>
                        <InputLabel id="sandbox-selector-landing-page-label" sx={{ background: "white" }}>
                            Select Sandbox
                        </InputLabel>
                        <Select
                            labelId="sandbox-selector-landing-page-label"
                            color="info"
                            size="small"
                            value={sandbox?.sandbox_id ?? ""}
                            onChange={(event: any) => onSelectSandbox(event.target.value)}
                            fullWidth>
                            {sandboxes.map((sandbox: UserSandboxAccess) => (
                                <MenuItem value={sandbox.sandbox_id} key={sandbox.sandbox_id}>
                                    {sandbox.sandbox_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="allocation-type-selector-landing-page-label" sx={{ background: "white" }}>
                            Select Allocation Type
                        </InputLabel>
                        <Select
                            labelId="allocation-type-selector-landing-page-label"
                            color="info"
                            size="small"
                            value={allocationType.id}
                            onChange={(event: any) => setSelectedAllocationType(event.target.value)}
                            fullWidth>
                            {ALLOCATION_TYPES.map((typeItem: any) => (
                                <MenuItem value={typeItem.id} key={typeItem.key}>
                                    {typeItem.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item flex="1">
                    <Button
                        size="medium"
                        variant="contained"
                        disabled={selectedAllocationType.length && selectedSandbox?.sandbox_name}
                        onClick={onSelectConfiguration}
                        sx={{ textTransform: "none" }}>
                        Start
                    </Button>
                </Grid>
            </>
        );
    };

    return (
        <Grid item md={3} alignContent="end" justifyContent="end" mb="24px">
            <Card className={styles.SelectConfiguration}>
                <CardContent
                    sx={{
                        p: "12px !important",
                    }}>
                    <Grid item container gap="16px" alignItems="end">
                        {renderSelectConfigurations()}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default React.memo(SelectConfiguration);
