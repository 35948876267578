/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createReview = /* GraphQL */ `mutation CreateReview($input: CreateReviewInput!) {
  createReview(input: $input) {
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewMutationVariables,
  APITypes.CreateReviewMutation
>;
export const createSystem = /* GraphQL */ `mutation CreateSystem($input: CreateSystemInput!) {
  createSystem(input: $input) {
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSystemMutationVariables,
  APITypes.CreateSystemMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const addComment = /* GraphQL */ `mutation AddComment($input: AddCommentInput!) {
  addComment(input: $input) {
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddCommentMutationVariables,
  APITypes.AddCommentMutation
>;
export const manageReview = /* GraphQL */ `mutation ManageReview($input: ManageReviewRequest!) {
  manageReview(input: $input) {
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ManageReviewMutationVariables,
  APITypes.ManageReviewMutation
>;
export const manageReviewerReview = /* GraphQL */ `mutation ManageReviewerReview($input: ManageReviewerReviewRequest!) {
  manageReviewerReview(input: $input) {
    status
    review {
      reviewId
      reviewStatus
      reviewJson
      system
      timestamp
      requesterId
      resourceIdentifier
      reviewers
      reviewDescription
      reviewedTimestamp
      __typename
    }
    reviewStatus
    configStatus
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ManageReviewerReviewMutationVariables,
  APITypes.ManageReviewerReviewMutation
>;
export const createConfiguration = /* GraphQL */ `mutation CreateConfiguration($input: CreateConfigWithReviewInput!) {
  createConfiguration(input: $input) {
    reviewStatus
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConfigurationMutationVariables,
  APITypes.CreateConfigurationMutation
>;
export const createVersion = /* GraphQL */ `mutation CreateVersion($input: CreateVersionWithReviewInput!) {
  createVersion(input: $input) {
    reviewStatus
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVersionMutationVariables,
  APITypes.CreateVersionMutation
>;
export const createConfigurationInDataStore = /* GraphQL */ `mutation CreateConfigurationInDataStore($input: CreateConfigurationRequest!) {
  createConfigurationInDataStore(input: $input) {
    string
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConfigurationInDataStoreMutationVariables,
  APITypes.CreateConfigurationInDataStoreMutation
>;
export const createVersionInDataStore = /* GraphQL */ `mutation CreateVersionInDataStore($input: CreateConfigurationRequest!) {
  createVersionInDataStore(input: $input) {
    string
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVersionInDataStoreMutationVariables,
  APITypes.CreateVersionInDataStoreMutation
>;
export const createTenant = /* GraphQL */ `mutation CreateTenant($input: CreateTenantRequest!) {
  createTenant(input: $input) {
    string
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTenantMutationVariables,
  APITypes.CreateTenantMutation
>;
export const createGFTOneNotificationRequest = /* GraphQL */ `mutation CreateGFTOneNotificationRequest(
  $input: CreateGFTOneNotificationRequestInput!
) {
  createGFTOneNotificationRequest(input: $input) {
    allNotifications {
      id
      app
      channel
      stage
      recipients
      notificationType
      isSplitGroup
      sender
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGFTOneNotificationRequestMutationVariables,
  APITypes.CreateGFTOneNotificationRequestMutation
>;
export const createGFTOneUserSubscription = /* GraphQL */ `mutation CreateGFTOneUserSubscription(
  $input: CreateGFTOneUserSubscriptionRequest!
) {
  createGFTOneUserSubscription(input: $input) {
    id
    status
    app
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGFTOneUserSubscriptionMutationVariables,
  APITypes.CreateGFTOneUserSubscriptionMutation
>;
export const deleteGFTOneUserSubscription = /* GraphQL */ `mutation DeleteGFTOneUserSubscription(
  $input: DeleteGFTOneUserSubscriptionRequest!
) {
  deleteGFTOneUserSubscription(input: $input) {
    id
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGFTOneUserSubscriptionMutationVariables,
  APITypes.DeleteGFTOneUserSubscriptionMutation
>;
export const updateGFTOneUserSubscription = /* GraphQL */ `mutation UpdateGFTOneUserSubscription(
  $input: UpdateGFTOneUserSubscriptionRequest!
) {
  updateGFTOneUserSubscription(input: $input) {
    id
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGFTOneUserSubscriptionMutationVariables,
  APITypes.UpdateGFTOneUserSubscriptionMutation
>;
export const updateGFTOneUINotification = /* GraphQL */ `mutation UpdateGFTOneUINotification(
  $input: UpdateGFTOneUINotificationRequest!
) {
  updateGFTOneUINotification(input: $input) {
    id
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGFTOneUINotificationMutationVariables,
  APITypes.UpdateGFTOneUINotificationMutation
>;
export const getAllGFTOneUINotifications = /* GraphQL */ `mutation GetAllGFTOneUINotifications($input: GFTOneNotificationsInput) {
  getAllGFTOneUINotifications(input: $input) {
    items {
      id
      app
      channel
      alias
      created_at
      eventType
      stageSystemKey
      seen
      read
      sender
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GetAllGFTOneUINotificationsMutationVariables,
  APITypes.GetAllGFTOneUINotificationsMutation
>;
export const createResource = /* GraphQL */ `mutation CreateResource($input: CreateBindleResourceRequest!) {
  createResource(input: $input) {
    resourceID
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateResourceMutationVariables,
  APITypes.CreateResourceMutation
>;
export const CreateSandbox = /* GraphQL */ `mutation CreateSandbox($userAlias: String!, $request: CreateSandboxRequest!) {
  CreateSandbox(userAlias: $userAlias, request: $request) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    created_at
    status
    sandbox_name
    owner_bindle_id
    allocation_types
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSandboxMutationVariables,
  APITypes.CreateSandboxMutation
>;
export const UpdateSandbox = /* GraphQL */ `mutation UpdateSandbox(
  $userAlias: String!
  $sandbox_id: String!
  $request: UpdateSandboxRequest!
) {
  UpdateSandbox(
    userAlias: $userAlias
    sandbox_id: $sandbox_id
    request: $request
  ) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    created_at
    status
    sandbox_name
    owner_bindle_id
    allocation_types
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSandboxMutationVariables,
  APITypes.UpdateSandboxMutation
>;
export const DeprecateSandbox = /* GraphQL */ `mutation DeprecateSandbox($userAlias: String!, $sandbox_id: String!) {
  DeprecateSandbox(userAlias: $userAlias, sandbox_id: $sandbox_id) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    created_at
    status
    sandbox_name
    owner_bindle_id
    allocation_types
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeprecateSandboxMutationVariables,
  APITypes.DeprecateSandboxMutation
>;
export const ReviveSandbox = /* GraphQL */ `mutation ReviveSandbox($userAlias: String!, $sandbox_id: String!) {
  ReviveSandbox(userAlias: $userAlias, sandbox_id: $sandbox_id) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    created_at
    status
    sandbox_name
    owner_bindle_id
    allocation_types
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ReviveSandboxMutationVariables,
  APITypes.ReviveSandboxMutation
>;
export const DisableSandbox = /* GraphQL */ `mutation DisableSandbox($userAlias: String!, $sandbox_id: String!) {
  DisableSandbox(userAlias: $userAlias, sandbox_id: $sandbox_id) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    created_at
    status
    sandbox_name
    owner_bindle_id
    allocation_types
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DisableSandboxMutationVariables,
  APITypes.DisableSandboxMutation
>;
export const EnableSandbox = /* GraphQL */ `mutation EnableSandbox($userAlias: String!, $sandbox_id: String!) {
  EnableSandbox(userAlias: $userAlias, sandbox_id: $sandbox_id) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    created_at
    status
    sandbox_name
    owner_bindle_id
    allocation_types
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EnableSandboxMutationVariables,
  APITypes.EnableSandboxMutation
>;
export const UpdateInputMetadata = /* GraphQL */ `mutation UpdateInputMetadata($s3_path: String!) {
  UpdateInputMetadata(s3_path: $s3_path) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    created_at
    status
    input_type
    allocation_type
    s3_path
    userAlias
    dictionary_input_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInputMetadataMutationVariables,
  APITypes.UpdateInputMetadataMutation
>;
export const DeprecateInput = /* GraphQL */ `mutation DeprecateInput($sandbox_id: String!, $input_id: String!) {
  DeprecateInput(sandbox_id: $sandbox_id, input_id: $input_id) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    created_at
    status
    input_type
    allocation_type
    s3_path
    userAlias
    dictionary_input_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeprecateInputMutationVariables,
  APITypes.DeprecateInputMutation
>;
export const ReviveInput = /* GraphQL */ `mutation ReviveInput($sandbox_id: String!, $input_id: String!) {
  ReviveInput(sandbox_id: $sandbox_id, input_id: $input_id) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    created_at
    status
    input_type
    allocation_type
    s3_path
    userAlias
    dictionary_input_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ReviveInputMutationVariables,
  APITypes.ReviveInputMutation
>;
export const CreateSim = /* GraphQL */ `mutation CreateSim($sandbox_id: String!, $request: CreateSimRequest!) {
  CreateSim(sandbox_id: $sandbox_id, request: $request) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    name
    status
    created_at
    updated_at
    started_at
    finished_at
    error_msg
    configs
    allocation_type
    input_ids
    mwaa_env
    dag_run_id
    scenario
    timeRange
    dag_tasks_status
    report_id
    is_shared
    shared_to_sandbox_ids
    shared_from_sandbox_id
    userAlias
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSimMutationVariables,
  APITypes.CreateSimMutation
>;
export const StartSim = /* GraphQL */ `mutation StartSim($sandbox_id: String!, $sim_id: String!, $userAlias: String!) {
  StartSim(sandbox_id: $sandbox_id, sim_id: $sim_id, userAlias: $userAlias) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    name
    status
    created_at
    updated_at
    started_at
    finished_at
    error_msg
    configs
    allocation_type
    input_ids
    mwaa_env
    dag_run_id
    scenario
    timeRange
    dag_tasks_status
    report_id
    is_shared
    shared_to_sandbox_ids
    shared_from_sandbox_id
    userAlias
    __typename
  }
}
` as GeneratedMutation<
  APITypes.StartSimMutationVariables,
  APITypes.StartSimMutation
>;
export const CancelSim = /* GraphQL */ `mutation CancelSim(
  $sandbox_id: String!
  $sim_id: String!
  $userAlias: String!
) {
  CancelSim(sandbox_id: $sandbox_id, sim_id: $sim_id, userAlias: $userAlias) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    name
    status
    created_at
    updated_at
    started_at
    finished_at
    error_msg
    configs
    allocation_type
    input_ids
    mwaa_env
    dag_run_id
    scenario
    timeRange
    dag_tasks_status
    report_id
    is_shared
    shared_to_sandbox_ids
    shared_from_sandbox_id
    userAlias
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CancelSimMutationVariables,
  APITypes.CancelSimMutation
>;
export const DeprecateSim = /* GraphQL */ `mutation DeprecateSim(
  $sandbox_id: String!
  $sim_id: String!
  $is_shared: Boolean
  $userAlias: String!
) {
  DeprecateSim(
    sandbox_id: $sandbox_id
    sim_id: $sim_id
    is_shared: $is_shared
    userAlias: $userAlias
  ) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    name
    status
    created_at
    updated_at
    started_at
    finished_at
    error_msg
    configs
    allocation_type
    input_ids
    mwaa_env
    dag_run_id
    scenario
    timeRange
    dag_tasks_status
    report_id
    is_shared
    shared_to_sandbox_ids
    shared_from_sandbox_id
    userAlias
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeprecateSimMutationVariables,
  APITypes.DeprecateSimMutation
>;
export const ReviveSim = /* GraphQL */ `mutation ReviveSim(
  $sandbox_id: String!
  $sim_id: String!
  $is_shared: Boolean
  $userAlias: String!
) {
  ReviveSim(
    sandbox_id: $sandbox_id
    sim_id: $sim_id
    is_shared: $is_shared
    userAlias: $userAlias
  ) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    name
    status
    created_at
    updated_at
    started_at
    finished_at
    error_msg
    configs
    allocation_type
    input_ids
    mwaa_env
    dag_run_id
    scenario
    timeRange
    dag_tasks_status
    report_id
    is_shared
    shared_to_sandbox_ids
    shared_from_sandbox_id
    userAlias
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ReviveSimMutationVariables,
  APITypes.ReviveSimMutation
>;
export const UpdateSim = /* GraphQL */ `mutation UpdateSim(
  $sandbox_id: String!
  $sim_id: String!
  $request: UpdateSimRequest!
) {
  UpdateSim(sandbox_id: $sandbox_id, sim_id: $sim_id, request: $request) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    name
    status
    created_at
    updated_at
    started_at
    finished_at
    error_msg
    configs
    allocation_type
    input_ids
    mwaa_env
    dag_run_id
    scenario
    timeRange
    dag_tasks_status
    report_id
    is_shared
    shared_to_sandbox_ids
    shared_from_sandbox_id
    userAlias
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSimMutationVariables,
  APITypes.UpdateSimMutation
>;
export const UpdateSimStatus = /* GraphQL */ `mutation UpdateSimStatus(
  $sandbox_id: String!
  $sim_id: String!
  $request: UpdateSimStatusRequest!
) {
  UpdateSimStatus(sandbox_id: $sandbox_id, sim_id: $sim_id, request: $request) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    name
    status
    created_at
    updated_at
    started_at
    finished_at
    error_msg
    configs
    allocation_type
    input_ids
    mwaa_env
    dag_run_id
    scenario
    timeRange
    dag_tasks_status
    report_id
    is_shared
    shared_to_sandbox_ids
    shared_from_sandbox_id
    userAlias
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSimStatusMutationVariables,
  APITypes.UpdateSimStatusMutation
>;
export const ShareSim = /* GraphQL */ `mutation ShareSim(
  $sandbox_id: String!
  $sim_id: String!
  $request: ShareSimRequest!
) {
  ShareSim(sandbox_id: $sandbox_id, sim_id: $sim_id, request: $request)
}
` as GeneratedMutation<
  APITypes.ShareSimMutationVariables,
  APITypes.ShareSimMutation
>;
export const UnshareSimFromSandbox = /* GraphQL */ `mutation UnshareSimFromSandbox(
  $sandbox_id: String!
  $sim_id: String!
  $request: ShareSimRequest!
) {
  UnshareSimFromSandbox(
    sandbox_id: $sandbox_id
    sim_id: $sim_id
    request: $request
  )
}
` as GeneratedMutation<
  APITypes.UnshareSimFromSandboxMutationVariables,
  APITypes.UnshareSimFromSandboxMutation
>;
